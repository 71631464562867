<template>
  <div>
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <div class="policy">

      <div class="container">
        <!-- <div style="padding-top: 20px;">
            <el-page-header style="font-size: 28px;" >
            </el-page-header>
          </div> -->
        <div class="policy-content">

          <div class="policy-content-top">
            <div class="content-top-title">
              {{ dataList.title }}
            </div>
            <div class="content-top-time">
              <div>时间：{{ dataList.created }}</div>
              <div>|</div>
              <div>浏览量：{{ dataList.hits }}</div>
            </div>
          </div>
          <div ref="content" class="w-e-text-container policy-content-text">
            <div data-slate-editor v-html="dataList.content"></div>
          </div>
        </div>
      </div>
    </div>
    <footerBottom></footerBottom>
  </div>
</template>

<script>
import { httpGet } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import footerBottom from "@/components/footer/footer.vue"
import '@wangeditor/editor/dist/css/style.css';

export default {
  name: "policyDetails",
  data() {
    return {
      dataList: [],
      pageIndex: 0,
    }
  },
  mounted() {
    this.getCourseDetailsMsg();
  },
  methods: {
    getCourseDetailsMsg() {
      httpGet("/news-info/detail",
        {
          newsId: this.$route.query.id
        },
        (res) => {
          // console.log(res.data.content)
          this.dataList = res.data;
          // this.$nextTick(() => {
          //   let table = this.$refs.content.querySelector("table");
          //   if(table){
          //    table.style.width = "100%";
          //   }
          // });
        },
        (err) => {
          // console.log(err)
          throw err
        })
    }
  },
  components: {
    topNav: topNav,
    footerBottom
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.policy-breadcrumb {
  position: absolute;
  top: 0;
  left: 8%;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 64px;
  //background: transparent;
  //background-color: #d0d0d0;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
}

.policy-logo {
  width: 100%;
  height: 242px;
}

.policy-content {
  text-align: left;
  background-color: #fff;
  padding: 0 50px 20px 50px;
  margin-top: 30px;
  margin-bottom: 40px;

  .policy-content-top {
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #EFF0F2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-top-title {
      padding-bottom: 12px;
      font-size: 32px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }

    .content-top-time {
      width: 300px;
      display: flex;
      justify-content: space-between;
      font-size: 17px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
    }
  }

  .policy-content-text {
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 50px;
    white-space: pre-wrap;
    margin-bottom: 114px;

    table {
      width: 100% !important;
    }
  }

}

.laster {
  width: 100%;
  //height: 60px;
  position: relative;
  bottom: 0;
  left: 0;
}

.bottom {
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/img/pre/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}
</style>